html,
#root {
  @apply h-full w-full;
}
body {
  @apply bg-app text-[#E9F2EE] font-semibold h-full w-full;
}
::selection {
  @apply text-white bg-primary/25;
}
button svg {
  margin: auto 0;
  @apply text-xl;
}

p {
  @apply !leading-[40px] opacity-75;
}

.features .swiper {
  @apply !p-[32px];
}

.swiper {
  padding: 25px 0 !important;
}
